<template>
	<div class="pui-form">
		<mregla-modals :modelName="modelName"></mregla-modals>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<pui-field-set class="ml-4 mr-4" :title="getTitleReglas">
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<v-row dense>
							<v-col cols="4">
								<pui-text-field
									:id="`nombre-mregla`"
									v-model="model.nombre"
									:label="$t('mregla.nombre')"
									:disabled="formDisabled"
									toplabel
									maxlength="100"
									required
								></pui-text-field>
							</v-col>
						</v-row>
						<pui-field-set :title="getTitleCondiciones">
							<v-row dense>
								<v-col cols="4">
									<pui-select
										id="idpuertolocal"
										attach="idpuertolocal"
										:label="$t('mregla.idpuertolocal')"
										toplabel
										clearable
										:required="!getAlgunoRellenado"
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmpuertolocal"
										:itemsToSelect="[{ id: model.idpuertolocal }]"
										:modelFormMapping="{ id: 'idpuertolocal' }"
										itemValue="id"
										itemText="descripcion"
										:order="{ descripcion: 'asc' }"
									></pui-select>
								</v-col>
								<v-col cols="4">
									<pui-select
										id="idtipoman"
										attach="idtipoman"
										:label="$t('mregla.idtipoman')"
										toplabel
										clearable
										:required="!getAlgunoRellenado"
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmtipomanipulacion"
										:itemsToSelect="[{ id: model.idtipoman }]"
										:modelFormMapping="{ id: 'idtipoman' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
									></pui-select>
								</v-col>
								<v-col cols="4">
									<pui-select
										id="idagente"
										attach="idagente"
										:label="$t('mregla.idagente')"
										toplabel
										clearable
										:required="!getAlgunoRellenado"
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmagentetipo"
										:itemsToSelect="[{ id: model.idagente }]"
										:modelFormMapping="{ id: 'idagente' }"
										itemValue="id"
										:itemText="item => `${item.nif} - ${item.nombre}`"
										:order="{ id: 'asc' }"
										:filterId="`idagente-mregla`"
										:filterMap="{ tipoagente: 8 }"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										id="idgrupo"
										attach="idgrupo"
										:label="$t('mregla.idgrupo')"
										toplabel
										clearable
										:required="!getAlgunoRellenado"
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmgrupommpp"
										:itemsToSelect="[{ grmpcodi: model.idgrupo }]"
										:modelFormMapping="{ grmpcodi: 'idgrupo' }"
										itemValue="grmpcodi"
										:itemText="item => `${item.grmpcodi} - ${item.grmpdesc}`"
										:order="{ grmpcodi: 'asc' }"
									></pui-select>
								</v-col>
								<v-col cols="4">
									<pui-select
										:id="`idareaopera-mregla`"
										attach="idareaopera"
										:label="$t('mregla.idareaopera')"
										toplabel
										clearable
										:required="!getAlgunoRellenado"
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmareaoperacion"
										:itemsToSelect="[{ idareaopera: model.idareaopera }]"
										:modelFormMapping="{ idareaopera: 'idareaopera' }"
										itemValue="idareaopera"
										:itemText="item => `${item.codareaopera} - ${item.codareaoperaedi} - ${item.name}`"
										:order="{ name: 'asc' }"
										:filterId="`idpuertolocal-mregla`"
										:filterMap="{ idpuertolocal: model.idpuertolocal }"
									></pui-select>
								</v-col>
								<v-col cols="4">
									<pui-number-field
										:id="`peso-mregla`"
										:label="$t('mregla.peso')"
										v-model="model.peso"
										maxlength="18"
										suffix="kg"
										:disabled="formDisabled"
										toplabel
										:required="!getAlgunoRellenado"
									></pui-number-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-checkbox
										:id="`articulo15-mregla`"
										:label="$t('mregla.articulo15')"
										v-model="model.articulo15"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
								<v-col cols="4">
									<pui-checkbox
										:id="`indexcluirbuqcisterna-mregla`"
										:label="$t('mregla.indexcluirbuqcisterna')"
										v-model="model.indexcluirbuqcisterna"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4">
									<pui-select
										id="idpaisorigen"
										attach="idpaisorigen"
										:label="$t('mregla.idpaisorigen')"
										toplabel
										clearable
										reactive
										:disabled="model.idlocalidadorigen != null"
										v-model="model"
										modelName="vlupmpais"
										:itemsToSelect="[{ codigo: model.idpaisorigen }]"
										:modelFormMapping="{ codigo: 'idpaisorigen' }"
										itemValue="codigo"
										:itemText="item => `${item.descripcion} - ${item.codlocode}`"
										:order="{ descripcion: 'asc' }"
										:required="!getAlgunoRellenado"
									></pui-select>
								</v-col>
								<v-col cols="4">
									<pui-select
										id="idlocalidadorigen"
										attach="idlocalidadorigen"
										:label="$t('mregla.idlocalidadorigen')"
										toplabel
										clearable
										reactive
										:disabled="model.idpaisorigen != null"
										v-model="model"
										modelName="vlupmlocalidad"
										:itemsToSelect="[{ id: model.idlocalidadorigen }]"
										:modelFormMapping="{ id: 'idlocalidadorigen' }"
										itemValue="id"
										:itemText="item => `${item.name} - ${item.codlocodepais}${item.codlocode}`"
										:order="{ name: 'asc' }"
										:required="!getAlgunoRellenado"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
						<pui-field-set :title="getTitleRechazo">
							<v-row dense>
								<v-col cols="1">
									<pui-text-field
										:id="`codrechazo-mregla`"
										v-model="model.codrechazo"
										:label="$t('mregla.codrechazo')"
										disabled
										toplabel
										onlyread
										maxlength="3"
									></pui-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="8">
									<pui-text-area
										:id="`desrechazo-mregla`"
										v-model="model.desrechazo"
										:label="$t('mregla.desrechazo')"
										:disabled="formDisabled"
										maxlength="350"
										toplabel
										required
									></pui-text-area>
								</v-col>
							</v-row>
						</pui-field-set>
						<v-row dense>
							<v-col cols="3">
								<pui-date-field
									:id="`fecinivigencia-mregla`"
									:label="$t('mregla.fecinivigencia')"
									toplabel
									required
									time
									v-model="model.fecinivigencia"
									:disabled="formDisabled"
								></pui-date-field>
							</v-col>
							<v-col cols="3">
								<pui-date-field
									:id="`fecfinvigencia-mregla`"
									:label="$t('mregla.fecfinvigencia')"
									toplabel
									time
									v-model="model.fecfinvigencia"
									:disabled="formDisabled"
									:min="model.fecinivigencia"
								></pui-date-field>
							</v-col>
							<v-col cols="4">
								<pui-checkbox
									class="mt-6"
									:id="`activa-mregla`"
									:label="$t('mregla.activa')"
									v-model="model.activa"
									true-value="1"
									false-value="0"
								></pui-checkbox>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</pui-field-set>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mreglaActions from './MreglaActions';
import mreglaModals from './MreglaModals.vue';

export default {
	name: 'mregla-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mregla-modals': mreglaModals
	},
	data() {
		return {
			modelName: 'mregla',
			actions: mreglaActions.formActions
		};
	},
	methods: {
		afterGetData() {
			const url = '/mregla/getCodigoRechazo';
			if (this.isCreatingElement) {
				this.$puiRequests.getRequest(
					url,
					{},
					response => {
						this.$set(this.model, 'codrechazo', response.data);
					},
					e => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiNotify.error(message);
					}
				);
			}
		}
	},
	computed: {
		getTitleReglas() {
			return this.$t('menu.mregla');
		},
		getTitleCondiciones() {
			return this.$t('mregla.condiciones');
		},
		getTitleRechazo() {
			return this.$t('mregla.rechazo');
		},
		getAlgunoRellenado() {
			return (
				this.model.idpuertolocal != null ||
				this.model.idtipoman != null ||
				this.model.idagente != null ||
				this.model.idgrupo != null ||
				this.model.idareaopera != null ||
				this.model.peso != null ||
				this.model.articulo15 == 1 ||
				this.model.idpaisorigen != null ||
				this.model.idlocalidadorigen != null
			);
		}
	},
	created() {}
};
</script>
